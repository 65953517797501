.hidden-assistive-content-wcag {
	position: absolute;
	left: -10000px;
	top: auto;
	width: 1px;
	height: 1px;
	overflow: hidden;
}

#products_module.responsive {
	clear: both;
}

#products_module.responsive * {
	box-sizing: border-box;
}

/* General */
#products_module.responsive a {
	border: 0;
}

#products_module.responsive .with-margin-bottom {
	margin-bottom: 6px;
}

#products_module.responsive .with-margin-top {
	margin-top: 6px;
}

#products_module.responsive .middle-aligner {
	vertical-align: middle;
}

#products_module.responsive .no-image-available img.no-image {
	border: 0;
}

.products_module .item-sale-price,
#products_module.responsive .item-sale-price {
	color: #e00000 !important;
	font-weight: bold;
}

.products_module .item-old-price,
#products_module.responsive .item-old-price {
	text-decoration: line-through;
}

.products_module .item-sku,
#products_module.responsive .item-sku {
	font-size: 0.7em;
	line-height: normal;
	margin-bottom: 5px;
}

#products_module.responsive .category-heading {
	margin-bottom: 10px;
}

#products_module.responsive .category-heading .category-banner {
	margin-bottom: 10px;
}

#products_module.responsive .footer {
	margin-top: 30px;
}

/* Grid & List Views - Shared */
#products_module.responsive .items {
	text-align: center;
}

#products_module.responsive .items a.middle-aligner + p {
	text-align: left;
}

#products_module.responsive .items:after {
	content: "";
	display: block;
	clear: both;
}

#cross_sells .item-wrapper,
#products_module.responsive .items .item-wrapper {
	float: left;
}

#products_module.responsive .items .item-wrapper .item .item-image {
	padding: 5px;
	border: 1px solid #e2e2e2;
	font-size: 0 !important;
}

#cross_sells .items .item-wrapper .item .item-image .item-image-wrapper,
#products_module.responsive .items .item-wrapper .item .item-image a {
	display: block;
	overflow: hidden;
}

#products_module.responsive .items .item-wrapper .item .item-image img {
	max-height: 100%;
	max-width: 100%;
}

#products_module.responsive .items .item-wrapper .item .item-description {
	margin-top: 3px;
	border: 0;
	text-align: left;
}

#products_module.responsive .items .item-wrapper .item .item-description p {
	font-family: inherit;
	font-size: inherit;
	font-weight: inherit;
	line-height: inherit;
	margin: 0;
}

#products_module.responsive .items .item-wrapper .item .item-description .item-short-description p {
	margin: 0;
	padding: 0;
}

#products_module.responsive .items .item-wrapper .affiliate-url-products-list {
	width: 100%;
	margin-top: 3px;
}

#products_module.responsive .items .item-wrapper .item .item-description .metadata1.cms_title h3 {
	font-size: inherit;
	margin: 0;
}

/* Grid View */

#cross_sells .item-wrapper .item,
#products_module.responsive .items.grid-view .item-wrapper .item {
	margin: 10px 0 10px 0;
	padding: 5px 0;
	vertical-align: top;
}

#cross_sells .item-wrapper .item .item-image .item-image-wrapper,
#products_module.responsive .items.grid-view .item-wrapper .item .item-image a {
	height: 151px;
	line-height: 151px;
}

/* Adjust spacing between the items */
#cross_sells .items,
#products_module.responsive .items.grid-view {
	margin-right: -10px;
}

#cross_sells .items .item-wrapper .item,
#products_module.responsive .items.grid-view .item-wrapper .item {
	margin-right: 10px;
}

/* List View */
#products_module.responsive .items.list-view .item-wrapper {
	width: 100%;
}

#products_module.responsive .items.list-view .item-wrapper .item {
	float: left;
	width: 100%;
	margin: 7px 0 7px 0;
	vertical-align: top;
}

#products_module.responsive .items.list-view .item-wrapper .item .item-image {
	float: left;
	width: 100px;
}

#cross_sells .items.list-view .item-wrapper .item .item-image .item-image-wrapper,
#products_module.responsive .items.list-view .item-wrapper .item .item-image a {
	height: 88px;
	line-height: 88px;
}

#products_module.responsive .items.list-view .item-wrapper .item .item-description {
	width: auto;
	margin-top: 0;
	margin-left: 110px;
}

#products_module.responsive .sort-by-brands-only #SortBy {
	display: none;
}

/* Layout Specific */
#products_module.responsive .items .item-wrapper .item.tile-layout-2 .item-image {
	width: 48%;
	float: left;
}

#products_module.responsive .items .item-wrapper .item.tile-layout-2 .item-description {
	width: 48%;
	float: right;
}

#products_module.responsive .items #transition-overlay {
	display: none;
	position: absolute;
	width: 100%;
	height: 100%;
	background: #ffffff;
	left: 0;
	top: 0;
	z-index: 100000;
}

#products_module.responsive .items #transition-overlay.show-overlay {
	display: block;
	opacity: 1;
}

#products_module.responsive .items #transition-overlay #transition-overlay-content {
	margin: 0 auto;
	margin-top: 3em;
}

/* Add To Cart In List View & Grid View */

#products_module.responsive .items .item-wrapper .item-add-to-cart.loading-data {
	background: url( "/core/public/shared/assets/images/userinterface/processing.gif" ) 50% 50% no-repeat;
	height: 30px;
	display: block;
}

#products_module.responsive .items .item-wrapper .item-add-to-cart {
	background: #f6f6f6;
	padding: 5px 7px;
	display: none;
}

#products_module.responsive .items .item-wrapper .item-add-to-cart.loaded {
	display: block;
}

#products_module.responsive .items .item-wrapper .item-add-to-cart:after {
	content: "";
	display: block;
	clear: both;
}

#products_module.responsive .items .item-wrapper .product-attributes-list-view {
	overflow: hidden;
}

#products_module.responsive .items .item-wrapper .list-view-cart-row {
	text-align: left;
	margin: 0 0 3px 0;
	font-size: 0.95em;
	margin-top: 5px;
}

#products_module.responsive .items .item-wrapper .list-view-cart-row label {
	text-align: left;
	font-weight: normal;
	margin-right: 4px;
	color: #686868;
	display: block;
	line-height: 25px;
}

#products_module.responsive .items .item-wrapper button.primary {
	margin-bottom: 0;
	font-size: 1rem;
}

#products_module.responsive .items .item-wrapper .list-view-cart-row.quantity-field-container label {
	display: inline-block;
}

#products_module.responsive .items .item-wrapper .list-view-cart-row.quantity-field-container input {
	width: 3em;
	display: inline-block;
	font-size: inherit;
	line-height: normal;
}

#products_module.responsive .items .item-wrapper .list-view-cart-row.quantity-field-container .product-quantity-unit {
	padding-left: 0.2em;
}

#products_module.responsive .items .item-wrapper .list-view-cart-row.quantity-field-container .minimum-quantity-label,
#products_module.responsive .items .item-wrapper .list-view-cart-row.quantity-field-container .maximum-quantity-label {
	padding-top: 0.2em;
	width: 100%;
}

#products_module.responsive .items .item-wrapper .list-view-cart-row.out-of-stock-container {
	display: inline-block;
	width: auto;
	padding-top: 12px;
	margin-left: 10px;
}

#products_module.responsive .items .item-wrapper.variant-item .list-view-cart-row.out-of-stock-container {
	width: 122px;
	text-align: center;
}

#products_module.responsive .items .item-wrapper .list-view-cart-row.denomination-row label {
	display: inline-block;
}

#products_module.responsive .items .item-wrapper .list-view-cart-row.denomination-row input {
	width: 3em;
	display: inline-block;
}

#products_module.responsive .items .item-wrapper .list-view-cart-row .custom-options-attribute .select-custom-options-wrapper {
	display: table;
	width: 100%;
}

#products_module.responsive .items .item-wrapper .list-view-cart-row .custom-options-attribute select {
	display: table-cell;
	vertical-align: middle;
}

#products_module.responsive .items .item-wrapper .list-view-cart-row .custom-options-attribute .attributeSelectionThumbnail {
	display: table-cell;
	padding-left: 0.2em;
	vertical-align: middle;
	width: 30px;
}

#products_module.responsive .items .item-wrapper .list-view-cart-row .not-available-combination {
	width: 100%;
	color: red;
}

#products_module.responsive .items .item-wrapper .list-view-cart-row .attribute-datepicker {
	width: 100%;
}

#products_module.responsive .items .item-wrapper .list-view-cart-row select {
	width: 100%;
}

#products_module.responsive .items .item-wrapper .list-view-cart-row .accepted-formats {
	word-wrap: break-word;
}


/* Details View */
#products_module.responsive.cms_entity .product-details-slideshow-wrapper {
	float: left;
}

#products_module.responsive.cms_entity .product-details-slideshow-wrapper.centered {
	float: none;
	margin: 0 auto;
}

#products_module.responsive.cms_entity .product-details-slideshow-wrapper .product-details-slideshow {
	text-align: center;
}

#products_module.responsive.cms_entity .product-details-slideshow-wrapper .product-details-slideshow .main-slider-wrapper {
	position: relative;
}

#products_module.responsive.cms_entity .product-details-slideshow-wrapper .product-details-slideshow .lightbox-magnifier-message {
	margin-top: 15px;
}

#products_module.responsive.cms_entity .product-details-slideshow-wrapper .product-details-slideshow .lightbox-magnifier-message.with-slider {
	margin-bottom: 15px;
}

#products_module.responsive.cms_entity .product-details-slideshow-wrapper .product-details-slideshow a.one-image {
	display: block;
}

#products_module.responsive.cms_entity .product-details-slideshow-wrapper .product-details-slideshow .one-image {
	position: relative;
}

#products_module.responsive.cms_entity .product-details-slideshow-wrapper .product-details-slideshow .one-image img {
	max-width: 100%;
}

#products_module.responsive.cms_entity .product-details-slideshow-wrapper .product-details-slideshow .overlay {
	display: none;
}

#products_module.responsive.cms_entity .product-details-info-wrapper {
	float: left;
}

#products_module.responsive.cms_entity .product-details-info-wrapper.no-images {
	width: 100%;
}

#products_module.responsive.cms_entity .product-details-info-wrapper.no-images .product-details-info {
	margin-left: 0;
}

#products_module.responsive.cms_entity .product-details-info-wrapper.no-images .add_to_cart_form .cart-estimate-button {
	width: 225px;
	display: block;
}

#products_module.responsive.cms_entity .product-details-info-wrapper .product-details-info {
	padding: 15px 5px 15px 15px;
	background-color: #f6f6f6;
}

#products_module.responsive.cms_entity .product-details-info-wrapper .product-details-info .add_to_cart_form:after {
	content: "";
	display: block;
	clear: both;
}



#products_module.responsive.cms_entity .social-media-sharing {
	text-align: center;
	margin-top: 15px;
}

#products_module.responsive.cms_entity .social-media-text {
	float: left;
}

#products_module.responsive.cms_entity .social-media-items {
	float: left;
}

#products_module.responsive.cms_entity .social-media-icon {
	display: inline-block;
	margin-left: 5px;
	width: 25px;
	height: 25px;
	background-size: auto;
	background-repeat: no-repeat;
	background-position: 50% 50%;
}

#products_module.responsive.cms_entity .social-media-icon.facebook {
	background-image: url("/core/website/modules/eCommerceProducts/images/socialmedia/facebook.svg");
}

#products_module.responsive.cms_entity .social-media-icon.facebook:hover {
	background-image: url("/core/website/modules/eCommerceProducts/images/socialmedia/facebook-over.svg");
}

#products_module.responsive.cms_entity .social-media-icon.twitter {
	background-image: url("/core/website/modules/eCommerceProducts/images/socialmedia/twitter.svg");
}

#products_module.responsive.cms_entity .social-media-icon.twitter:hover {
	background-image: url("/core/website/modules/eCommerceProducts/images/socialmedia/twitter-over.svg");
}

#products_module.responsive.cms_entity .social-media-icon.pinterest {
	background-image: url("/core/website/modules/eCommerceProducts/images/socialmedia/pinterest.svg");
}

#products_module.responsive.cms_entity .social-media-icon.pinterest:hover {
	background-image: url("/core/website/modules/eCommerceProducts/images/socialmedia/pinterest-over.svg");
}

#products_module.responsive.cms_entity .social-media-icon.google-plus {
	background-image: url("/core/website/modules/eCommerceProducts/images/socialmedia/googleplus.svg");
}

#products_module.responsive.cms_entity .social-media-icon.google-plus:hover {
	background-image: url("/core/website/modules/eCommerceProducts/images/socialmedia/googleplus-over.svg");
}

#products_module.responsive.cms_entity .social-media-icon.email {
	background-image: url("/core/website/modules/eCommerceProducts/images/socialmedia/email.svg");
}

#products_module.responsive.cms_entity .social-media-icon.email:hover {
	background-image: url("/core/website/modules/eCommerceProducts/images/socialmedia/email-over.svg");
}

#products_module.responsive .product-prices table {
	width: 100%;
}

#products_module.responsive .product-prices table th {
	text-align: left;
}

#products_module.responsive .product-prices table tr.odd {
	background: #e4e4e4;
}

#products_module.responsive .product-sku,
#products_module.responsive .product-brand,
#products_module.responsive .product-upc,
#products_module.responsive .product-hscode,
#products_module.responsive .product-msrp,
#products_module.responsive .product-weight,
#products_module.responsive .product-dimension {
	margin: 3px 0 0 0;
	font-size: 0.875rem;
}

#products_module.responsive .product-brand-with-image {
	text-align: center;
}

#products_module.responsive.cms_entity .related-items,
#products_module.responsive.cms_entity .recently-viewed-items {
	clear: both;
}

#products_module.responsive.cms_entity .related-items.slider {
	width: 90%;
	margin: 20px auto 50px auto;
}

#products_module.responsive.cms_entity .related-items .related-items-title,
#products_module.responsive.cms_entity .recently-viewed-items .recently-viewed-items-title {
	margin: 15px 0 5px 0;
	font-size: 0.875rem;
	font-weight: bold;
}

#products_module.responsive.cms_entity .related-items .related-item-separator,
#products_module.responsive.cms_entity .recently-viewed-items .recently-viewed-item-separator {
	margin-bottom: 5px;
}

#products_module.responsive.cms_entity .related-items.slider .slick-track {
	display: flex;
}

#products_module.responsive.cms_entity .related-items.slider .related-item {
	height: auto;
	background-color: #fff;
	margin-right: 10px;
}

#products_module.responsive.cms_entity .related-items.slider .related-item .related-item-inner {
	padding: 20px;
}

#products_module.responsive.cms_entity .related-items.slider .slick-dots {
	bottom: -38px;
}

#products_module.responsive.cms_entity .related-items.slider .slick-dots li button:before {
	font-size: 10px;
}

#products_module.responsive.cms_entity .related-items.slider .slick-prev,
#products_module.responsive.cms_entity .related-items.slider .slick-next {
	width: 40px;
	height: 40px;
	top: 65%;
}

#products_module.responsive.cms_entity .related-items.slider .slick-next {
	right: -45px;
}

#products_module.responsive.cms_entity .related-items.slider .slick-prev {
	left: -45px;
}

#products_module.responsive.cms_entity .related-items.slider .slick-prev:before,
#products_module.responsive.cms_entity .related-items.slider .slick-next:before {
	font-size: 30px;
	color: #666;
}

/* Product List Search Box */
#products_module #product-search-container {
	margin: 10px auto;
	margin-right: 30px;
	width: 250px;
	margin-bottom: 30px;
	position: relative;
}

@media (max-width: 497px) {
	#products_module #product-search-container {
		width: 100%;
	}
}

#products_module #product-search-container input.criteria {
	width: 100%;
}

#products_module #product-search-container input.criteria::-ms-clear {
	display: none;
}

#products_module #product-search-container .search-button {
	cursor: pointer;
	width: 2.5rem;
	margin-top: 0em;
	margin-bottom: 0em;
	padding-top: 0em;
	padding-bottom: 0em;
	border-radius: 0px;
	background-color: #ffffff;
	background-image: url('../images/search.svg');
	background-position: 50% 50%;
	background-size: 1em 1em;
	background-repeat: no-repeat;
	opacity: 0.65;
	color: transparent;
	font-size: 0.875rem;
	line-height: 1em;
	text-transform: uppercase;
	position: absolute;
	right: 2px;
	top: 2px;
	bottom: 2px;
	border: 0;
	font-size: 1rem;
}


/* Search View */

#products_module .criteria-label {
	width: 90px;
	float: left;
}

#products_module .product-category-selection {
	width: 100%;
	float: left;
	padding-left: 90px;
}

#products_module .brand-selection {
	width: 100%;
	float: left;
	padding-left: 90px;
}

#products_module .product-search input {
	margin: 0 0 7px 0;
	float: left;
	width: 40%;
}

#products_module .product-search select {
	margin: 0 5px 7px 0;
	float: left;
	width: auto;
}

#products_module .product-search select.type-of-criteria {
	margin-left: 10px;
}

#products_module .product-search .cms_buttons {
	padding-left: 90px;
	float: left;
}

#products_module .product-search-results {
	margin: 10px 0 0 0;
	display: inline-block;
	width: 100%;
}

#products_module .search-summary-display {
	width: 100%;
	display: inline-block;
	padding: 3px 0;
}

#products_module .search-summary-display-label {
	width: 30%;
	float: left;
}

#products_module .search-summary-display-label .search-summary-display-label-strong {
	font-weight: bold;
}

#products_module .search-results-summary {
	text-align: right;
	width: 70%;
	float: left;
}

/* Add to Cart - Begin */
#products_module.responsive .add_to_cart_form .quantity_row,
#products_module.responsive .add_to_cart_form .contentbox_item {
	color: #686868;
	width: 100%;
	float: left;
}

#products_module.responsive .add_to_cart_form .quantity_row {
	margin-top: 10px;
}

#products_module.responsive .add_to_cart_form .contentbox_item {
	margin-top: 5px;
}

#products_module.responsive .add_to_cart_form .contentbox_item:after {
	content: "";
	display: block;
	clear: both;
}

#products_module.responsive .add_to_cart_form .quantity_row .product-quantity-label {
	float: none;
	display: inline-block;
	width: 20%;
}

#products_module.responsive .add_to_cart_form .quantity_row .product-denomination-label {
	float: none;
	display: inline-block;
	width: 18%;
}

#products_module.responsive .add_to_cart_form .quantity_row .spacing {
	padding-left: 7px;
}

#products_module.responsive .add_to_cart_form .quantity_row input {
	margin-left: 3px;
	width: 50px;
	text-align: center;
	padding-left: 10px;
}

#products_module.responsive .add_to_cart_form .quantity_row span.form_money_currency {
	display: inline-block;
	position: relative;
	width: 2%;
	text-align: right;
}

#products_module.responsive .add_to_cart_form .quantity_row #quantity_available_info {
	width: 100%;
	font-size: 0.75rem;
	margin: 0 0 10px 0;
}

#products_module.responsive .add_to_cart_form .quantity_row #quantity_available_info.no_available_quantity {
	color: #ff0000;
	font-style: italic;
}

#products_module.responsive .add_to_cart_form .quantity_row #quantity_available_info.available_quantity {
	color: #567e3a;
	font-style: italic;
}

#products_module.responsive.cms_entity .product-details-info-wrapper .product-details-info .add_to_cart_form .denomination-validation-message,
#products_module.responsive.cms_entity .add_to_cart_form .minimum_quantity_label,
#products_module.responsive.cms_entity .add_to_cart_form .maximum_quantity_label {
	color: #ff0000;
	margin-left: 3px;
}

/*** Attribute Grid ***/
#products_module.responsive .attribute-grid-add-to-cart-container {
	background-color: #f6f6f6;
	padding: 10px 15px;
	margin-top: 10px;
	width: 100%;
	float: left;
}

#products_module.responsive .add_to_cart_form .attribute-grid-container {
	float: left;
	width: 100%;
}

#products_module.responsive.cms_entity .attribute-grid-add-to-cart-container .add_to_cart_form .cart-estimate-button {
	width: 225px;
	display: block;
}

#products_module.responsive.cms_entity .attribute-grid-add-to-cart-container #viewwishlist_wrapper,
#products_module.responsive.cms_entity .attribute-grid-add-to-cart-container #viewfavorites_wrapper {
	width: 225px;
	display: block;
}

#products_module.responsive .add_to_cart_form .attribute-grid-table-container {
	overflow-x: auto;
}

#products_module.responsive .add_to_cart_form .attribute-grid-container table thead {
	display: block;
	padding-right: 18px;
}

#products_module.responsive .add_to_cart_form .attribute-grid-container table tbody {
	display: block;
	max-height: 500px;
	overflow-y: auto;
}

#products_module.responsive .add_to_cart_form .attribute-grid-container table tr.attribute-grid-table-header th {
	padding: 2px 2px 10px 2px;
	text-align: center;
	font-weight: bold;
	max-width: 80px;
	min-width: 80px;
	vertical-align: bottom;
}

#products_module.responsive .add_to_cart_form .attribute-grid-container table tr.attribute-grid-table-header th label {
	text-align: center !important;
}

#products_module.responsive .add_to_cart_form .attribute-grid-container table tr.attribute-grid-table-header th:first-child label {
	text-align: left !important;
}

#products_module.responsive .add_to_cart_form .attribute-grid-container .attribute-grid-table tr td.attribute-grid-option-label {
	word-wrap: break-word;
}

#products_module.responsive .add_to_cart_form .attribute-grid-container table thead tr.attribute-grid-table-header th img {
	max-width: 100%;
}

#products_module.responsive .add_to_cart_form .attribute-grid-container table tr td {
	padding: 0 2px 10px 2px;
	text-align: center;
	font-weight: normal;
	vertical-align: top;
	max-width: 80px;
	width: 80px;
}

#products_module.responsive .add_to_cart_form .attribute-grid-container.two-dimension-attribute-grid table tr td:first-child {
	text-align: left;
	max-width: none;
}

#products_module.responsive .add_to_cart_form .attribute-grid-container table tr td .quantity-grid-quantity-field {
	width: 100%;
	border: 1px solid #cccccc;
	display: block;
	font-size: 0.875rem;
	height: 38px;
	padding: 8px 12px;
	vertical-align: middle;
	width: 100%;
	text-align: center;
	margin: 0;
}

#products_module.responsive .add_to_cart_form .attribute-grid-container table tr td .quantity-grid-quantity-field.erroneous-quantity {
	border: 1px solid #ff0000;
}

#products_module.responsive .add_to_cart_form .attribute-grid-container table tr td .attribute-grid-quantity-container {
	min-height: 45px;
}

#products_module.responsive .add_to_cart_form .attribute-grid-container table tr td .attribute-grid-quantity-container.with-price-adjustments {
	min-height: 62px;
}

#products_module.responsive .add_to_cart_form .attribute-grid-container table tr td .attribute-grid-subtable {
	display: table;
	width: 100%;
	table-layout: fixed;
}

#products_module.responsive .add_to_cart_form .attribute-grid-container table tr td .attribute-grid-subtable-row {
	display: table-row;
	width: 100%;
}

#products_module.responsive .add_to_cart_form .attribute-grid-container table tr td .attribute-grid-subtable-cell {
	display: table-cell;
	vertical-align: middle;
	width: 50%;
	padding-right: 5px;
	word-wrap: break-word;
}

#products_module.responsive .add_to_cart_form .attribute-grid-price-adjustment {
	font-size: 0.75rem;
	line-height: 14px;
	padding-top: 4px;
}

#products_module.responsive .add_to_cart_form .attribute-grid-container table tr td .mobile-label-attribute-grid.attribute-grid-subtable-cell {
	display: none;
}

#products_module.responsive .attribute-grid-add-to-cart-container .quantity_row {
	margin-top: 0px;
}

#products_module.responsive .add_to_cart_form .use_attribute_grid_row {
	width: 100%;
	margin-top: 10px;
	float: left;
	color: #686868;
	display: table;
}

#products_module.responsive .attribute-grid-add-to-cart-container .use_attribute_grid_row {
	margin-top: 0px;
}

#products_module.responsive .add_to_cart_form .use_attribute_grid_row .use-attribute-grid-label {
	display: table-cell;
	font-weight: normal;
	font-size: 0.875rem;
}

#products_module.responsive .add_to_cart_form .use_attribute_grid_row .use-attribute-grid-field {
	display: table-cell;
	width: 15px;
	margin-right: 10px;
}


/*** Attribute Grid End***/

#products_module.responsive .add_to_cart_form .subtotal_row {
	color: #686868;
	margin-top: 8px;
	float: left;
	width: 100%;
	margin-bottom: 5px;
}

#products_module.responsive .add_to_cart_form .subtotal_row label {
	display: inline-block;
	font-weight: normal;
	margin: 0;
	vertical-align: middle;
}

#products_module.responsive .add_to_cart_form .subtotal_row strong {
	color: #000;
	font-size: 1rem;
	float: left;
}

#products_module.responsive .add_to_cart_form .notavailable_row {
	color: #f00;
	margin-top: 8px;
	float: left;
	width: 100%;
}

#products_module.responsive .add_to_cart_form .submit_row {
	float: left;
	width: 100%;
}

#products_module.responsive .add_to_cart_form .submit_row_center {
	text-align: center;
}

#products_module.responsive .add_to_cart_form .submit_row #CalculateSubtotal {
	margin-bottom: 4px;
}

#products_module.responsive .add_to_cart_form #AddToWishList {
	float: none;
}

#products_module.responsive .add_to_cart_form #AddToWishList:hover {
	text-decoration: none;
}

#products_module.responsive #viewwishlist_wrapper,
#products_module.responsive #viewfavorites_wrapper {
	text-align: center;
}

#products_module.responsive #viewwishlist_wrapper {
	margin-top: 5px;
}

#products_module.responsive .add_to_cart_form button {
	margin-top: 5px;
}

#products_module.responsive .add_to_cart_form .form_button {
	padding-left: 0;
	padding-right: 0;
}

#products_module.responsive .add_to_cart_form .cart-estimate-button {
	padding: 5px;
	width: 75%;
}

#products_module.responsive .add_to_cart_form .notify-when-available-button {
	padding: 5px;
	width: 75%;
}

.product-availability-form-container input {
	padding: 5px;
	width: 75%;
}

#products_module.responsive .add_to_cart_form .attributeSelectionLabel {
	font-weight: normal;
	line-height: 25px;
}

#products_module.responsive .add_to_cart_form .attributeSelection {
	margin-bottom: 5px;
}

#products_module.responsive .attributeSelectionWithImages {
	width: 100%;
	float: left;
}

#products_module.responsive .attributeSelectionWithImages .attributeSelectDescription,
#products_module.responsive .attributeSelectionWithImages .attributeCaption {
	display: block;
}

#products_module.responsive .attributeSelectionWithImages ul {
	padding: 0px;
	margin: 0px;
}

#products_module.responsive .attributeSelectionWithImages ul li {
	list-style-type: none;
	float: left;
	line-height: normal;
}

#products_module.responsive .attributeSelectionWithImages ul li span {
	display: none;
}

#products_module.responsive .attributeSelectionWithImages ul li label {
	display: inline-block;
	border: 1px solid transparent;
	margin: 0;
	cursor: pointer;
}

#products_module.responsive .attributeSelectionWithImages ul li label.disabled-option {
	cursor: default;
	opacity: 0.25;
}

#products_module.responsive .attributeSelectionWithImages.attribute-option-labels ul li {
	width: 100%;
}

#products_module.responsive .attributeSelectionWithImages.attribute-option-labels ul li span {
	display: inline-block;
	padding-right: 10px;
}

#products_module.responsive .attributeSelectionWithImages.attribute-option-labels ul li label {
	width: 100%;
}

#products_module.responsive .attributeSelectionWithImages ul li label img {
	display: inline-block;
	border: 1px solid transparent;
	pointer-events: none;
}

#products_module.responsive .attributeOptionList {
	float: left;
	width: 100%;
}

#products_module.responsive .attributeSelectionWithImages.attribute-option-selected ul li.attribute-option-hightlight label img {
	opacity: 1;
	border: 2px solid transparent;
}

#products_module.responsive .attributeSelectionWithImages.attribute-option-selected ul li.attribute-option-hightlight label img {
	border: 2px solid rgba(0, 0, 0, 0.75);
}

#products_module.responsive .attributeSelectionWithImages .spinner-loader-wrapper {
	float: left;
	width: 100%;
	display: none;
	margin-top: 3px;
}

#products_module.responsive .attributeSelectionWithImages .spinner-loader-outside {
	width: 30%;
	float: left;
	padding-right: 10px;
	text-align: right;
}

#products_module.responsive .attributeSelectionWithImages .spinner-loader {
	text-align: center;
	font-size: 0.25rem;
	color: #000000;
	line-height: 25px;
	float: right;
}

#products_module.responsive .attributeSelectionWithImages .spinner-loader-loading-text {
	width: 70%;
	font-size: 0.75rem;
	float: left;
	line-height: 20px;
}

#products_module.responsive .tooltipDiv {
	background-color: #ffffff;
	border: 1px solid #e1e0c2;
}

#products_module.responsive .tooltipDiv img {
	padding: 5px;
}

#products_module.responsive .viewMoreAttributeOptions,
#products_module.responsive .hideMoreAttributeOptions {
	width: 100%;
	display: inline-block;
}

#products_module.responsive .add_to_cart_form .attribute-type-singleline input {
	width: 100%;
}

#products_module.responsive .add_to_cart_form .attribute-type-textarea textarea {
	width: 100%;
	height: 60px;
	font-family: sans-serif;
	line-height: normal;
}

#products_module.responsive .add_to_cart_form .attribute-type-date .attribute-datepicker {
	display: inline-block;
	padding-right: 26px;
}

.ui-datepicker select {
	display: inline-block;
}

#products_module.responsive .add_to_cart_form .attribute-type-date img {
	margin-left: -23px;
	display: inline-block;
}


#products_module.responsive .affiliate_box .show-more-affiliates-button,
#products_module.responsive .affiliate_box ul.affiliate-urls-list .affiliate_url {
	padding-left: 3px;
}

#products_module.responsive .affiliate_box .show-more-affiliates-button {
	font-size: 0.85rem;
}

#products_module.responsive .affiliate_box ul.affiliate-urls-list li.more-affiliate-urls {
	display: none;
}

#products_module.responsive .affiliate_box ul.affiliate-urls-list {
	padding: 0px;
	margin: 0px;
}

#products_module.responsive .affiliate_box ul.affiliate-urls-list li {
	list-style-type: none;
	margin: 0;
}

#products_module.responsive .affiliate_box .affiliate_description {
	width: 100%;
}

#products_module.responsive .availability_box .available {
	color: #42b4e6;
	font-style: italic;
}

#products_module.responsive .availability_box .unavailable {
	color: #cc0000;
	font-style: italic;
}

.responsive.attributeSectionBody {
	width: 100%;
}

.responsive.attributeSectionBody a.closelink {
	text-align: right;
	width: 100%;
	float: left;
	margin-bottom: 0.5rem;
	font-size: 0.8em;
}

.responsive.attributeSectionBody #attributeSelectionLargePreview {
	width: 100%;
	float: left;
	padding: 0 5px;
}

.responsive.attributeSectionBody #attributeSelectionOptions {
	padding-top: 15px;
	float: left;
	width: 100%;
}

.responsive.attributeSectionBody .attributeSelectionOption {
	width: 16.66%;
	padding: 0 0 10px 10px;
	float: left;
}

.responsive.attributeSectionBody .attributeSelectionOption img {
	max-width: 100%;
	max-height: 100%;
	padding: 3px;
}

.responsive.attributeSectionBody .attributeSelectionOption img.attributeSelectionOptionSelected {
	border: 1px solid #cccccc;
}

.product-attribute-file-upload input {
	border: 1px solid #cccccc;
	font-size: 0.688rem;
	max-width: 100%;
}

.product-attribute-file-upload label {
	font-weight: normal;
}

.contentbox_item .accepted-formats {
	font-size: 0.688rem;
}

.contentbox_item .special-instructions {
	font-size: 0.75rem;
}

/* Add to Cart - End */

/* Tabs - Begin */
#products_module.responsive.cms_entity .product-details-tabs {
	clear: both;
	padding-top: 15px;
	float: left;
	margin-top: 15px;
	width: 100%;
}

#products_module .product-details-tabs.one_tab_only .tab-item {
	cursor: auto;
}

#products_module.responsive.cms_entity .product-details-tabs .tab-item {
	font-size: 1rem;
	position: relative;
	padding: 7px;
	border-left: 1px solid #d6d6d6;
	border-right: 1px solid #d6d6d6;
	border-bottom: 1px solid #d6d6d6;
	cursor: pointer;
	white-space: nowrap;
	color: #5c585e;
	text-transform: uppercase;
}

#products_module.responsive.cms_entity .product-details-tabs .tab-item:hover {
	background-color: #d6d6d6;
	cursor: pointer;
}

#products_module.responsive.cms_entity .product-details-tabs .selected-tab-item:hover {
	background-color: #ffffff;
	cursor: pointer;
}

.tab-item-content-print-title {
	display: none;
}

#products_module.responsive.cms_entity .product-details-tabs .tab-item-content:after {
	content: "";
	display: block;
	clear: both;
}

#products_module.responsive.cms_entity .product-details-tabs .tab-item-content {
	padding: 15px;
	border-bottom-left-radius: 4px;
	border-bottom-right-radius: 4px;
	display: none;
	border-left: 1px solid #d6d6d6;
	border-right: 1px solid #d6d6d6;
	border-bottom: 1px solid #d6d6d6;
	min-height: 100%;
	width: 100%;
	margin-bottom: 15px;
}

#products_module.responsive.cms_entity .tab-item-content .separator {
	clear: both;
	border-top: 1px dashed #d6d6d6;
	height: 1px;
	margin-bottom: 10px;
	margin-top: 10px;
}

#products_module.responsive.cms_entity .tab-item-content .product-document {
	overflow: hidden;
	margin-bottom: 5px;
	text-align: left;
}

#products_module.responsive.cms_entity .tab-item-content .product-document .product-document-icon {
	float: left;
	border: 0;
	width: 24px;
	height: 24px;
}

#products_module.responsive.cms_entity .tab-item-content .product-document a {
	vertical-align: middle;
}

#products_module.responsive.cms_entity .tab-item-content .product-document .product-document-link {
	line-height: 24px;
	padding-left: 5px;
}

#products_module.responsive.cms_entity .tab-item-content .product-document .product-document-information {
	margin-left: 30px;
	opacity: 0.7;
	font-size: 0.75rem;
	margin-top: 2px;
}

#products_module.responsive.cms_entity .tab-item-content .product-questions {
	margin: 0 0 20px 0;
}

#products_module.responsive.cms_entity .tab-item-content .product-question p {
	font-weight: bold;
	margin-bottom: 0;
}

#products_module.responsive.cms_entity .tab-item-content .product-question-answer {
	margin: 5px 0 0 10px;
}

#products_module.responsive.cms_entity .tab-item-content .product-question-have-a-question {
	padding-top: 10px;
	border-top: 1px dashed #d6d6d6;
	font-weight: bold;
}


#products_module.responsive .tab-item-content .product-review {
	margin-top: 15px;
	margin-bottom: 15px;
	padding: 10px 15px;
}

#products_module.responsive .tab-item-content .product-review .product-review-author {
	font-size: 0.688rem;
	font-weight: bold;
}

#products_module.responsive.cms_entity .product-details-tabs .selected-tab-item-content {
	display: block;
	background-color: #fff;
}

#products_module.responsive.cms_entity .product-details-tabs.single_tab-only .selected-tab-item,
#products_module.responsive.cms_entity .product-details-tabs.single_tab-only .selected-tab-item-content,
#products_module.responsive.cms_entity .product-details-tabs.single_tab-only .tab-item,
#products_module.responsive.cms_entity .product-details-tabs.single_tab-only .tab-item-content {
	background-color: transparent;
	border: none;
}
/* Tabs - End */

/* Search colum */

.search_submit {
	width: 28px;
	height: 20px;
	background-image: url('../images/search.png');
	background-repeat: no-repeat;
	background-color: #ffffff;
	cursor: pointer;
	border: 1px solid #cccccc;
}

.search_submit:hover {
	background-image: url('../images/search_over.png');
}


/*Search Facets Region -Start */

.search-facet-filter-by,
.search-facet-collapse-icon {
	cursor: pointer;
}

.search-facet.collapsed .search-facet-options-list {
	display: none;
}


/*Search Facets Region -End*/

/* Faceted Search Breadcrumbs - Start*/
#faceted-search-breadcrumbs {
	float: left;
}

.filter-applied-wrap {
	border: 1px solid #cccccc;
	display: block;
	float: left;
	height: 30px;
	margin: 0 10px 10px 0px;
}

.filter-applied-x {
	background-image: url("/core/public/shared/assets/images/icon_x.svg");
	background-position: 50% 50%;
	background-repeat: no-repeat;
	background-size: auto 10px;
	float: right;
	min-width: 0;
	opacity: 0.25;
	transition: background-color 150ms ease 0s, opacity 150ms ease 0s;
	width: 28px;
	height: 100%;
}

.filter-applied-x:hover {
	background-color: #dddddd;
	opacity: 1;
}

.filter-applied-label {
	float: left;
	font-size: 0.625rem;
	font-weight: normal;
	padding-left: 5px;
	padding-right: 5px;
	padding-top: 6px;
	text-align: center;
}

.filters-applied-title {
	color: #686868;
	font-size: 0.8em;
	font-weight: bold;
	text-transform: uppercase;
	float: left;
	margin: 3px;
	width: 100%;
}

.filter-applied-clear-all {
	float: left;
	height: 30px;
}

.clear-all-x {
	background-image: url("/core/public/shared/assets/images/icon_x.svg");
	background-position: 50% 50%;
	background-repeat: no-repeat;
	background-size: auto 10px;
	float: right;
	height: 100%;
	min-width: 36px;
	opacity: 0.5;
	width: 36px;
}

.clear-all {
	margin-top: 6px;
	float: left;
}
/* Faceted Search Breadcrumbs - End*/

/* Forms - Begin */
#products_module.responsive.cms_form .information {
	margin-top: 10px;
}

#products_module.responsive.cms_form .required-fields-information {
	text-align: right;
}

#products_module.responsive.cms_form .ui-form-textarea > textarea {
	resize: vertical;
}

#products_module.responsive.cms_form .divider {
	height: 0;
	margin-bottom: 5px;
	margin-top: 5px;
}

#products_module.responsive.cms_form .star {
	float: left;
	cursor: pointer;
	padding: 7px 0;
}

#products_module.responsive.cms_form .preferred-contact-method-label.ui-form-label {
	font-weight: bold;
	display: block;
}
/* Forms - End */

/* *************************************************************************************************** */
/* ******************************************* Mobile ************************************************ */
/* *************************************************************************************************** */

/* ***************************************** Portrait **************************************** */
@media screen and (max-width: 480px) {
	/* Grid View */

	/* One Per Row */
	#products_module.responsive .items.grid-view.one-col-on-mobile-portrait .item-wrapper {
		width: 100%;
	}

	#products_module.responsive .items.grid-view.one-col-on-mobile-portrait .item-wrapper:nth-child(1n + 2) {
		clear: both;
	}

	/* Two Per Row */
	#products_module.responsive .items.grid-view.two-col-on-mobile-portrait .item-wrapper {
		width: 50%;
	}

	#products_module.responsive .items.grid-view.two-col-on-mobile-portrait .item-wrapper:nth-child(2n + 3) {
		clear: both;
	}

	.responsive.attributeSectionBody .attributeSelectionOption {
		width: 33.33%;
	}

	.attributeSectionBody a.closelink {
		font-size: 0.8em;
	}

	#products_module .product-search input {
		margin: 0 0 7px 0;
		width: 100%;
	}

	#products_module .product-search select {
		margin: 0 0 7px 0;
		float: left;
		width: 100%;
	}

	#products_module .product-search .product-category-selection,
	#products_module .product-search .brand-selection,
	#products_module .product-search .cms_buttons {
		padding-left: 0;
	}

	#products_module .product-search .cms_buttons {
		width: 100%;
	}

	#products_module .search-summary-display-label {
		width: 100%;
	}

	#products_module .search-results-summary {
		text-align: left;
		width: 100%;
	}

	#products_module.responsive .add_to_cart_form .quantity_row .product-quantity-label {
		width: 50%;
	}

	#products_module.responsive .add_to_cart_form .quantity_row .product-denomination-label {
		width: 48%;
	}
}

/* ***************************************** Landscape **************************************** */
@media screen and (min-width: 481px) and (max-width: 720px) {
	/* Grid View */

	/* One Per Row */
	#products_module.responsive .items.grid-view.one-col-on-mobile-landscape .item-wrapper {
		width: 100%;
	}

	#products_module.responsive .items.grid-view.one-col-on-mobile-landscape .item-wrapper:nth-child(1n + 2) {
		clear: both;
	}

	/* Two Per Row */
	#products_module.responsive .items.grid-view.two-col-on-mobile-landscape .item-wrapper {
		width: 50%;
	}

	#products_module.responsive .items.grid-view.two-col-on-mobile-landscape .item-wrapper:nth-child(2n + 3) {
		clear: both;
	}

	/* Three Per Row */
	#products_module.responsive .items.grid-view.three-col-on-mobile-landscape .item-wrapper {
		width: 33.33%;
	}

	#products_module.responsive .items.grid-view.three-col-on-mobile-landscape .item-wrapper:nth-child(3n + 4) {
		clear: both;
	}

	.responsive.attributeSectionBody .attributeSelectionOption {
		width: 25%;
	}

	#products_module.responsive .add_to_cart_form .quantity_row .product-quantity-label {
		width: 30%;
	}

	#products_module.responsive .add_to_cart_form .quantity_row .product-denomination-label {
		width: 28%;
	}
}

@media screen and (max-width: 720px) {
	/* Details View */
	#products_module.responsive.cms_entity .product-details-slideshow-wrapper {
		width: 100%;
	}

	#products_module.responsive.cms_entity .product-details-slideshow-wrapper .product-details-slideshow .lightbox-magnifier-message,
	#products_module.responsive.cms_entity .product-details-slideshow-wrapper .product-details-slideshow .lightbox-magnifier-message.with-slider {
		display: none;
	}

	#products_module.responsive.cms_entity .product-details-info-wrapper {
		width: 100%;
	}

	#products_module.responsive.cms_entity .product-details-info-wrapper .product-details-info {
		margin-top: 15px;
	}

	/* Add to Cart - Begin */
	#products_module.responsive .add_to_cart_form .cart-estimate-button {
		width: 100%;
		margin-bottom: 5px;
	}

	#products_module.responsive .add_to_cart_form .notify-when-available-button {
		width: 100%;
		margin-bottom: 5px;
	}

	.product-availability-form-container input {
		width: 100%;
		margin-bottom: 5px;
	}
	/* Add to Cart - End */

	/* Tabs - Begin */
	#products_module.responsive.cms_entity .product-details-tabs {
		width: 100%;
		border-top: 1px solid #d6d6d6;
	}

	#products_module.responsive.cms_entity .product-details-tabs .tab-item,
	#products_module.responsive.cms_entity .product-details-tabs .tab-item:hover {
		background-color: #e7e7e7;
	}

	#products_module.responsive.cms_entity .product-details-tabs .selected-tab-item {
		font-weight: bold;
	}
	/* Tabs - End */

	/* Forms - Begin */
	/*
	#products_module.responsive.cms_form .ui-form-text > input[type="text"] {
		width: 100%;
	}

	#products_module.responsive.cms_form .ui-form-textarea > textarea {
		width: 100%;
	}
	*/

	#products_module.responsive.cms_form .ui-form-note {
		line-height: normal;
	}

	#products_module.responsive.cms_form .ui-form-buttons {
		padding-left: 0;
		text-align: center;
	}

	#products_module.responsive.cms_form .ui-form-field {
		width: 100%;
	}
	/* Forms - End */
}

@media screen and (max-width: 600px) {

	/*** Attribute Grid ***/

	#products_module.responsive .add_to_cart_form .attribute-grid-container table thead {
		display: none;
	}

	#products_module.responsive .add_to_cart_form .attribute-grid-container table tbody {
		max-height: none;
	}

	#products_module.responsive.cms_entity .attribute-grid-add-to-cart-container .add_to_cart_form .cart-estimate-button {
		width: 100%;
	}

	#products_module.responsive .add_to_cart_form .attribute-grid-container .attribute-grid-table {
		border-bottom: 1px solid rgba(0, 0, 0, 0.2);
		margin-bottom: 20px;
		width: 100%;
	}

	#products_module.responsive .add_to_cart_form .attribute-grid-container .attribute-grid-table thead tr {
		display: block;
		width: 100%;
	}

	#products_module.responsive .add_to_cart_form .attribute-grid-container .attribute-grid-table tr td {
		display: block;
		min-height: 60px;
		padding: 10px;
		position: relative;
		text-align: left;
		width: auto;
	}

	#products_module.responsive .add_to_cart_form .attribute-grid-container .attribute-grid-table tr td.attribute-grid-option-label {
		background-color: rgba(0, 0, 0, 0.2);
		font-size: 1.2em;
		padding-right: 10px;
		text-transform: uppercase;
		width: 100%;
	}

	#products_module.responsive .add_to_cart_form .attribute-grid-container .attribute-grid-table input[type="text"] {
		display: inline-block;
		text-align: center;
	}

	#products_module.responsive .add_to_cart_form .attribute-grid-container table tr td {
		max-width: 100%;
		width: 100%;
	}

	#products_module.responsive .add_to_cart_form .attribute-grid-container table tr td .mobile-label-attribute-grid.attribute-grid-subtable-cell {
		display: table-cell;
	}

	/*** Attribute Grid End***/
}

@media screen and (min-width: 481px) and (max-width: 720px) {

	/* Add to Cart - Begin */
	#products_module.responsive .add_to_cart_form .cart-estimate-button {
		width: 42%;
		margin-bottom: 5px;
	}

	#products_module.responsive .add_to_cart_form .notify-when-available-button {
		width: 42%;
		margin-bottom: 5px;
	}

	.product-availability-form-container input {
		width: 42%;
		margin-bottom: 5px;
	}
	/* Add to Cart - End */

	#products_module.responsive .attributeSelectionWithImages.attribute-option-labels ul li {
		width: 49%;
	}
}

/* *************************************************************************************************** */
/* ******************************************** Tablet *********************************************** */
/* *************************************************************************************************** */
@media screen and (min-width: 768px) {

	.pagination-wrapper .pagination-sort-items {
		width: 35%;
	}

	.pagination-wrapper .pagination-page-selection-top {
		width: 40%;
	}

	.pagination-wrapper .pagination-current-items-top {
		width: 25%;
	}
}

@media screen and (min-width: 721px) {
	.w-col-9.s-col-9,
	.w-col-9 {
		width: 100%;
	}

	.left-nav-column {
		display: none;
	}

	/* Details View */
	#products_module.responsive.cms_entity .product-details-wrapper:after {
		content: "";
		display: block;
		clear: both;
	}

	#products_module.responsive.cms_entity .product-details-slideshow-wrapper {
		width: 50%;
	}

	#products_module.responsive.cms_entity .product-details-slideshow {
		margin-right: 10px;
	}

	#products_module.responsive.cms_entity .product-details-info-wrapper {
		width: 50%;
	}

	#products_module.responsive.cms_entity .product-details-info-wrapper .product-details-info {
		margin-left: 10px;
	}

	/* Tabs - Begin */
	#products_module.responsive.cms_entity .product-details-tabs .tab-item {
		float: left;
		top: 1px;
		margin: 0 3px 0 0;
		background-color: #f6f6f6;
		border-top-left-radius: 4px;
		border-top-right-radius: 4px;
		border-top: 1px solid #d6d6d6;
	}

	#products_module.responsive.cms_entity .product-details-tabs .selected-tab-item {
		background-color: #fff;
		border-bottom: 1px solid #fff;
	}

	#products_module.responsive.cms_entity .product-details-tabs .tab-item-content {
		float: left;
		border-top-right-radius: 4px;
		border-top: 1px solid #d6d6d6;
	}
	/* Tabs - End */

	/* Forms - Begin */
	#products_module.responsive.cms_form .ui-form-buttons .ui-form-field {
		width: auto;
	}
	/* Forms - End */
}

@media screen and (min-width: 721px) and (max-width: 1024px) {
	/* Grid View */

	/* One Per Row */
	#products_module.responsive .items.grid-view.one-col-on-tablet .item-wrapper {
		width: 100%;
	}

	#products_module.responsive .items.grid-view.one-col-on-tablet .item-wrapper:nth-child(1n + 2) {
		clear: both;
	}

	/* Two Per Row */
	#products_module.responsive .items.grid-view.two-col-on-tablet .item-wrapper {
		width: 50%;
	}

	#products_module.responsive .items.grid-view.two-col-on-tablet .item-wrapper:nth-child(2n + 3) {
		clear: both;
	}

	/* Three Per Row */
	#products_module.responsive .items.grid-view.three-col-on-tablet .item-wrapper {
		width: 33.33%;
	}

	#products_module.responsive .items.grid-view.three-col-on-tablet .item-wrapper:nth-child(3n + 4) {
		clear: both;
	}

	/* Four Per Row */
	#products_module.responsive .items.grid-view.four-col-on-tablet .item-wrapper {
		width: 25%;
	}

	#products_module.responsive .items.grid-view.four-col-on-tablet .item-wrapper:nth-child(4n + 5) {
		clear: both;
	}

	/* Five Per Row */
	#products_module.responsive .items.grid-view.five-col-on-tablet .item-wrapper {
		width: 20%;
	}

	#products_module.responsive .items.grid-view.five-col-on-tablet .item-wrapper:nth-child(5n + 6) {
		clear: both;
	}

	/* Six Per Row */
	#products_module.responsive .items.grid-view.six-col-on-tablet .item-wrapper {
		width: 16.66%;
	}

	#products_module.responsive .items.grid-view.six-col-on-tablet .item-wrapper:nth-child(6n + 7) {
		clear: both;
	}
}

/* *************************************************************************************************** */
/* ******************************************** Desktop ********************************************** */
/* *************************************************************************************************** */
@media screen and (min-width: 1025px) {
	.w-col-9.s-col-9,
	.w-col-9 {
		width: 75%;
	}

	.left-nav-column {
		display: block;
	}

	/* Details View */
	#products_module.responsive.cms_entity .product-details-slideshow-wrapper {
		width: 60%;
	}

	#products_module.responsive.cms_entity .product-details-info-wrapper {
		width: 40%;
	}

	/* One Per Row */
	#products_module.responsive .items.grid-view.one-col-on-desktop .item-wrapper {
		width: 100%;
	}

	#products_module.responsive .items.grid-view.one-col-on-desktop .item-wrapper:nth-child(1n + 2) {
		clear: both;
	}

	/* Two Per Row */
	#products_module.responsive .items.grid-view.two-col-on-desktop .item-wrapper {
		width: 50%;
	}

	#products_module.responsive .items.grid-view.two-col-on-desktop .item-wrapper:nth-child(2n + 3) {
		clear: both;
	}

	/* Three Per Row */
	#products_module.responsive .items.grid-view.three-col-on-desktop .item-wrapper {
		width: 33.33%;
	}

	#products_module.responsive .items.grid-view.three-col-on-desktop .item-wrapper:nth-child(3n + 4) {
		clear: both;
	}

	/* Four Per Row */
	#products_module.responsive .items.grid-view.four-col-on-desktop .item-wrapper {
		width: 25%;
	}

	#products_module.responsive .items.grid-view.four-col-on-desktop .item-wrapper:nth-child(4n + 5) {
		clear: both;
	}

	/* Five Per Row */
	#products_module.responsive .items.grid-view.five-col-on-desktop .item-wrapper {
		width: 20%;
	}

	#products_module.responsive .items.grid-view.five-col-on-desktop .item-wrapper:nth-child(5n + 6) {
		clear: both;
	}

	/* Six Per Row */
	#products_module.responsive .items.grid-view.six-col-on-desktop .item-wrapper {
		width: 16.66%;
	}

	#products_module.responsive .items.grid-view.six-col-on-desktop .item-wrapper:nth-child(6n + 7) {
		clear: both;
	}

	/* Seven Per Row */
	#products_module.responsive .items.grid-view.seven-col-on-desktop .item-wrapper {
		width: 14.28%;
	}

	#products_module.responsive .items.grid-view.seven-col-on-desktop .item-wrapper:nth-child(7n + 8) {
		clear: both;
	}

	/* Eight Per Row */
	#products_module.responsive .items.grid-view.eight-col-on-desktop .item-wrapper {
		width: 12.5%;
	}

	#products_module.responsive .items.grid-view.eight-col-on-desktop .item-wrapper:nth-child(8n + 9) {
		clear: both;
	}
}

/* Cross sells / Free Gifts */
#cross_sells {
	width: 100%;
	float: left;
	padding: 20px;
}

#cross_sells .quantity-field-container,
#cross_sells .item-wrapper .item-footer .add-to-cart-list-view .subtotal_row {
	display: none !important;
}

#cross_sells .list-view-cart-row.add-to-cart-button {
	margin-top: 4px;
}

#cross_sells .gifts-per-promotion-group {
	width: 100%;
	border: 2px dashed #555555;
	float: left;
	padding: 15px;
	margin: 0 0 25px 0;
}

#cross_sells .gifts-per-promotion-group .gift-selection-box-title {
	width: 100%;
}

#cross_sells .gifts-per-promotion-group .gift-selection-box-title .expand-collapse-gift-selection {
	display: inline-block;
}

#cross_sells .gifts-per-promotion-group .gift-selection-box-title .expand-collapse-gift-selection.use-arrow-open::before {
	content: '\2716';
	cursor: pointer;
	padding-right: 10px;
	padding-left: 4px;
}

#cross_sells .gifts-per-promotion-group .gift-selection-box-title .expand-collapse-gift-selection.use-arrow-closed::before {
	content: '\2795';
	cursor: pointer;
	padding-right: 10px;
}

#cross_sells .gifts-per-promotion-group .gift-selection-box-title .no-thanks-gift {
	display: inline-block;
	margin-left: 30px;
	background-color: #555555;
	color: #ffffff;
	border: none;
	cursor: pointer;
}

#cross_sells .gifts-per-promotion-group .change-gift-selection {
	font-weight: bold;
}

#cross_sells .slick-prev:before,
#cross_sells .slick-next:before {
	font-size: 2.5rem;
	line-height: 2.5rem;
	color: black;
	opacity: 0.75;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

#cross_sells .slick-prev:before {
	content: "<";
}

#cross_sells [dir="rtl"] .slick-prev:before {
	content: ">";
}

#cross_sells [dir="rtl"] .slick-next {
	left: -10px;
	top: 70px;
	right: auto;
}

#cross_sells .slick-next {
	height: 40px;
	width: auto;
	right: 10px;
}

#cross_sells .slick-prev {
	height: 40px;
	width: auto;
}

#cross_sells .slick-next:before {
	content: ">";
}

#cross_sells [dir="rtl"] .slick-next:before {
	content: "<";
}

#cross_sells .slick-slide:hover {
	cursor: default;
}

@media screen and (max-width: 480px) {
	#cross_sells .gifts-per-promotion-group .gift-selection-box-title .no-thanks-gift {
		margin: 5px 0 5px 0;
		clear: left;
		display: block;
	}
}

#cross_sells .gifts-per-promotion-group .added-to-cart-text {
	color: #808080;
}


/*** Product Search Suggestions ***/

#products_module.responsive .add_to_cart_form .fly-out-menu-container.search-suggestions .fly-out-menu-button {
	padding: 0;
	line-height: normal;
	min-height: 25px;
}

#products_module.responsive .add_to_cart_form .fly-out-menu-container.search-suggestions .fly-out-menu-button a {
	height: 26px;
	padding: 2px;
	line-height: 24px;
	color: inherit;
	font-weight: normal;
}

#products_module.responsive .add_to_cart_form .fly-out-menu-container.search-suggestions .fly-out-menu-button a.blank {
	padding-left: 5px;
}

#products_module.responsive .add_to_cart_form .fly-out-menu-container.search-suggestions .selection img {
	width: 22px;
	vertical-align: top;
	display: inline-block;
}

@media screen and (max-width: 480px) {
	#products_module.responsive .add_to_cart_form .fly-out-menu-container.search-suggestions .selection img {
		width: 26px;
	}

	#products_module.responsive .add_to_cart_form .fly-out-menu-container.search-suggestions .fly-out-menu-button a {
		height: 30px;
		line-height: 26px;
	}
}

.fly-out-menu-list-container.search-suggestions {
	max-height: 80%;
	border: 1px solid #dddddd;
	border-top-color: #eeeeee;
	border-bottom-color: #cccccc;
	box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.15);
}

@media (max-width: 767px) {
	.fly-out-menu-list-container.search-suggestions {
		box-shadow: 0px 14px 36px rgba(0, 0, 0, 0.25);
		width: 100%;
		border: 0;
	}

	.fly-out-menu-list-container.search-suggestions ul.fly-out-menu-list li.view-all-results {
		border-bottom: 1px solid #aaaaaa;
	}
}

.fly-out-menu-list-container.search-suggestions .fly-out-menu-list img {
	vertical-align: middle;
}

.fly-out-menu-list-container.search-suggestions .fly-out-menu-list a {
	padding: 1px 2px;
	/*line-height: 32px;*/
	min-height: 30px;
	/*height: 40px;*/
	color: inherit;
	font-weight: normal;
}

.fly-out-menu-list-container.search-suggestions .fly-out-menu-list a:after {
	content: "";
	display: block;
	clear: both;
}

.fly-out-menu-list-container.search-suggestions ul.fly-out-menu-list li a:focus,
.fly-out-menu-list-container.search-suggestions ul.fly-out-menu-list li a:hover {
	background: #ececec;
	color: inherit !important;
}

.fly-out-menu-list-container.search-suggestions ul.fly-out-menu-list li a:active,
.fly-out-menu-list-container.search-suggestions ul.fly-out-menu-list li.selected a {
	background: #2d86df !important;
	color: #ffffff !important;
}

.fly-out-menu-list-container.search-suggestions ul.fly-out-menu-list li a:active {
	transition: none;
}



.fly-out-menu-list-container.search-suggestions ul.fly-out-menu-list li {
	clear: both;
	border-bottom: 1px solid #eeeeee;
}

.fly-out-menu-list-container.search-suggestions ul.fly-out-menu-list li .item-image,
.fly-out-menu-list-container.search-suggestions ul.fly-out-menu-list li .description,
.fly-out-menu-list-container.search-suggestions ul.fly-out-menu-list li .item-price {
	float: left;
	white-space: normal;
}

.fly-out-menu-list-container.search-suggestions ul.fly-out-menu-list li .description,
.fly-out-menu-list-container.search-suggestions ul.fly-out-menu-list li .item-price {
	padding: 5px;
}

.fly-out-menu-list-container.search-suggestions ul.fly-out-menu-list li .item-image {
	width: 80px;
	min-height: 70px;
	line-height: 60px;
	background: #ffffff;
	text-align: center;
}

.fly-out-menu-list-container.search-suggestions ul.fly-out-menu-list li .description {
	min-width: 150px;
	max-width: 200px;
	padding-top: 5px;
}

.fly-out-menu-list-container.search-suggestions ul.fly-out-menu-list li .item-price {
	float: right;
	padding-top: 5px;
	padding-left: 8px;
}


@media screen and (max-width: 480px) {
	.fly-out-menu-list-container.search-suggestions ul.fly-out-menu-list li .item-image {
		width: 70px;
		min-height: 60px;
		line-height: 50px;
	}

	.fly-out-menu-list-container.search-suggestions ul.fly-out-menu-list li .description {
		max-width: calc(100% - 70px);
	}

	.fly-out-menu-list-container.search-suggestions ul.fly-out-menu-list li .item-price {
		display: none;
	}
}

.fly-out-menu-list-container.search-suggestions ul.fly-out-menu-list li .item-title {
	padding-top: 0;
}

.fly-out-menu-list-container.search-suggestions ul.fly-out-menu-list li .item-title h3 {
	font-size: inherit;
	margin: 0;
	padding: 0;
}

.fly-out-menu-list-container.search-suggestions ul.fly-out-menu-list li.view-all-results a {
	line-height: 32px;
	text-align: center;
	color: inherit;
	background: #f8f8f8;
}

@media screen and ( max-width: 760px ) {

	.fly-out-menu-list-container.search-suggestions ul.fly-out-menu-list li {
		border: 0;
		font-size: inherit;
		line-height: normal;
	}
}

.fly-out-menu-list-container.search-suggestions ul.fly-out-menu-list li.disabled-option span.option {
	opacity: 0.5;
}

.fly-out-menu-container .searching-indicator {
	position: absolute;
	top: 0;
	bottom: 0;
	right: 0;
	padding: 0;
	background: inherit;
	font-size: 20px;
	width: 26px;
	z-index: -1;
	background: #ffffff url( "/core/public/shared/assets/images/userinterface/processing.gif" ) 50% 50% no-repeat;
	transition: all 150ms ease 0s;
	visibility: visible;
	opacity: 0;
}

.fly-out-menu-container.searching .searching-indicator {
	z-index: 10000;
	visibility: visible;
	opacity: 1;
}

.fly-out-menu-list-container.search-suggestions .fly-out-menu-list .cart-item {
	color: #880000;
	float: right;
	line-height: 32px;
	margin-right: 5px;
}

.fly-out-menu-list-container.search-suggestions.searching {
	min-height: 50px;
}

.fly-out-menu-list-container.search-suggestions.searching .fly-out-menu-list {
	opacity: 0.2;
	transition: opacity 150ms ease 0s;
}

/* adjustments for swatch-preview */
#fly-out-menu-list-background .fly-out-menu-list-container.search-suggestions {
	min-height: 132px;
}

#fly-out-menu-list-background .fly-out-menu-list-container.search-suggestions .fly-out-menu-list {
	padding-left: 130px;
}

.fly-out-menu-list-container.search-suggestions .swatch-preview {
	display: none;
}

#fly-out-menu-list-background .fly-out-menu-list-container.search-suggestions .swatch-preview {
	display: block;
}


#fly-out-menu-list-background .fly-out-menu-list-container.search-suggestions .swatch-preview {
	width: 122px;
	height: 122px;
	position: fixed;
	margin: 5px;
	border: 1px solid #cccccc;
	background-color: #eeeeee;
}

#fly-out-menu-list-background .fly-out-menu-list-container.search-suggestions .swatch-preview.loading {
	background: #eeeeee url( "/core/public/shared/assets/images/userinterface/processing.gif" ) 50% 50% no-repeat;
}

#fly-out-menu-list-background .fly-out-menu-list-container.search-suggestions .swatch-preview img {
	min-width: 120px;
}


/*Variant selection assistant styles*/
#products_module.responsive .attributeSelectionWithImages ul li.not-eligible {
	position: relative;
}

#products_module.responsive .attributeSelectionWithImages ul li.not-eligible label {
	cursor: not-allowed;
}

#products_module.responsive .attributeSelectionWithImages ul li.not-eligible label::before {
	content: '';
	position: absolute;
	top: 0;
	left: 0;
	width: calc(100% - 2px);
	height: calc(100% - 2px);
	background-image: url( "/core/public/shared/assets/images/disabled-icon.svg" );
	background-size: 90% 90%;
	background-position: 50% 50%;
	background-repeat: no-repeat;
	opacity: 0.3;
}

#products_module.responsive .attributeSelection option.not-eligible {
	color: #757575;
	font-style: italic;
}
