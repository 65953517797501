/*link outlines*/
/* \core\libraries\slickSlider\assets\1.6.1\slick.css AND \core\libraries\slickSlider\assets\1.4.1\slick.css */
.slick-list:focus {
	outline: 1px dotted black;
}

/* \core\website\modules\eCommerceProducts\css\slider.css */
.slick-prev:hover,
.slick-prev:focus,
.slick-next:hover,
.slick-next:focus {
	outline: 1px dotted black;
}

/*contrast issues*/
/* \core\public\shared\assets\css\screen.css */
.search-facets .search-facet .price-range-button a {
	background-color: #757575;
	color: #ffffff;
}

/* \core\public\shared\assets\js\jquery\fullcalendar\3.2.0\fullcalendar.css */
.fc-basic-view .fc-day-top .fc-week-number {
	background-color: #f2f2f2;
	color: #606060;
}

/* \core\website\modules\events\css\screen.css */
#events_module.cms_list div.events_module_calendar .fc-view-container .fc-event {
	background-color: #767676;
	color: #000000;
}

/* \core\libraries\tablesaw\assets\2.0.3\tablesaw.bare.css */
.tablesaw-enhanced .tablesaw-bar .btn:hover,
.tablesaw-enhanced .tablesaw-bar .btn:focus {
	color: #1876bf;
	background-color: #fff;
}

/* \core\public\shared\assets\css\uitags.css */
.ui-form-legend h3 .ui-form-legend-step {
	background: #ffffff;
	color: #707070;
}

/* \core\modules\ecommerce\assets\css\responsive.css */
#s-checkout #s-steps-bar-small ul li .s-step {
	background: #746f76;
	color: #ffffff;
}

/* \core\modules\ecommerce\assets\css\all.css */
.payment-steps-bar .payment-step .payment-step-item .payment-step-item-number {
	color: #757575;
	background: #ffffff;
}

.payment-steps-bar .payment-step .payment-step-item {
	color: #757575;
	background: #d8d8d8;
}

/*relative font units*/
/* \core\website\modules\eCommerceProducts\css\responsive.css */
#cross_sells .slick-prev:before,
#cross_sells .slick-next:before {
	font-size: 2.5rem;
	line-height: 2.5rem;
}

/* \core\website\modules\eCommerceProducts\css\slider.css */
.slick-arrow-font {
	font-size: 1.25rem;
}

/* \core\modules\ecommerce\assets\css\all.css */
.payment-steps-bar .payment-step .payment-step-item div {
	font-size: 1rem;
}

.cart-section .shipped-shipments tfoot td {
	font-size: 0.0625rem;
	line-height: 0.0625rem;
}

.cart-section .internal-notes tfoot td {
	font-size: 0.0625rem;
	line-height: 0.0625rem;
}

#order-confirmation p {
	font-size: 0.8125rem;
}

#order-confirmation h2 {
	font-size: 1.0625rem;
}

.cart-section .order-product-notification-message button.order-product-notification-message-button[type=submit],
.cart-section .order-product-notification-message button.order-product-notification-message-button {
	font-size: 0.75rem !important;
	line-height: 1.125rem !important;
}

/* \core\modules\ecommerce\assets\css\responsive.css */
.order-product-notification-message button.order-product-notification-message-button[type=submit],
.order-product-notification-message button.order-product-notification-message-button {
	font-size: 0.75rem !important;
	line-height: 1.125rem !important;
}

/* \core\public\shared\assets\css\uitags.css */
.ui-form-legend {
	font-size: 1.0625rem;
}

.ui-form-note-extra {
	font-size: 0.6875rem;
}

/* \core\libraries\jquery.mmenu\assets\5.6.5\css\jquery.mmenu.all.css */
.mm-listview {
	font-size: 0.875rem;
}

.mm-listview .mm-divider {
	font-size: 0.625rem;
}

em.mm-counter {
	font-size: 0.875rem;
}

.mm-search input {
	font-size: 0.875rem;
}

.mm-noresultsmsg {
	font-size: 1.3125rem;
}

.mm-indexer {
	font-size: 0.75rem;
}

/* \core\libraries\magnificPopup\assets\1.0.0\magnific-popup.css */
.mfp-counter {
	font-size: 0.75rem;
	line-height: 1.125rem;
}

.mfp-figure small {
	font-size: 0.75rem;
	line-height: 0.875rem;
}

/* alerts screen.css */
#alerts_module.homepage_contentbox.list_alerts .alerts a:link,
#alerts_module.homepage_contentbox.list_alerts .alerts a:active,
#alerts_module.homepage_contentbox.list_alerts .alerts a:visited {
	font-size: 0.8125rem;
}

#alerts_module.homepage_contentbox.list_alerts .alerts a:hover,
#alerts_module.homepage_contentbox.list_alerts .alerts a.selected,
#alerts_module.homepage_contentbox.list_alerts .alerts a.selected:hover {
	font-size: 0.8125rem;
}

#alerts_module.homepage_contentbox.list_alerts .date {
	font-size: 0.6875rem;
	line-height: 0.875rem;
}

#alerts_module.homepage_contentbox.list_alerts .list_item .metadata1.title a {
	font-size: 0.6875rem;
	line-height: 0.875rem;
}

#alerts_module.homepage_contentbox.list_alerts .footer a {
	font-size: 0.625rem;
	line-height: 0.6875rem;
}

#alerts_module_subscribe_form.form .form_item .label,
#alerts_module_subscribe_form.form .form_item.required .label {
	font-size: 0.6875rem;
}

#alerts_module_subscribe_form.form .form_item .field {
	font-size: 0.6875rem;
}

#alerts_module_subscribe_form.form .form_item .field label,
#alerts_module_subscribe_form.form .form_item .field span,
#alerts_module_subscribe_form.form .form_item .field input,
#alerts_module_subscribe_form.form .form_item .field select {
	font-size: 0.6875rem;
}

/* submission forms screen.css */
#submissionforms_module.cms_form .cms_hint {
	font-size: 0.6875rem;
}

#submissionforms_module.cms_form .cms_date_hint {
	font-size: 0.6875rem;
}

#submissionforms_module.cms_form .agreement_hint {
	font-size: 0.6875rem;
}

.module-forms.confirmation .step-title {
	font-size: 1rem;
}

#submissionforms_module.homepage_contentbox .cms_hint,
#submissionforms_module.homepage_contentbox .cms_date_hint {
	font-size: 0.6875rem;
}

#submissionforms_module.homepage_contentbox .agreement_hint {
	font-size: 0.6875rem;
}

.module-forms .ui-form-field-payment-information,
.module-forms .ui-data-addresses h3 {
	font-size: 0.875rem;
}

/*submission forms print.css*/
.module-forms.confirmation .step-title {
	font-size: 0.875rem;
}
